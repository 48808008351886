import * as ethers from 'ethers'
import { BigNumber } from 'ethers'
import tokenAbi from '@core/abi/token'
import balancer from '@core/abi/balancer'

export const contractCreate = (provider, tokenAddress) => new ethers.Contract(tokenAddress, tokenAbi, provider)

export const ERC20Allowance = async (provider, tokenAddress, owner, spender) => {
  const contract = contractCreate(provider, tokenAddress)
  const value = await contract.allowance(owner, spender)
  const decimals = await contract.decimals()
  return parseFloat(ethers.utils.formatUnits(value, decimals))
}

export const ERC20Approve = async (signer, tokenAddress, amount, gasPrice) => {
  const spender = balancer.address

  const contract = contractCreate(signer, tokenAddress)
  const decimals = await contract.decimals()
  const value = ethers.utils.parseUnits(amount.toFixed(2), decimals)

  return contract.approve(spender, value, {
    gasPrice,
    gasLimit: BigNumber.from('800000'),
    nonce: await signer.getTransactionCount(signer.address),
  })
}

export default {
  ERC20Allowance,
  ERC20Approve,
  contractCreate,
}
