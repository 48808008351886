export default {
  address: '0xe73593281D5FA315cefCF160CF3889690c816f3a',
  abi: [
    {
      inputs: [
        {
          internalType: 'contract Position',
          name: '_position',
          type: 'address',
        },
        {
          internalType: 'contract Timeline',
          name: '_timeline',
          type: 'address',
        },
        {
          internalType: 'contract ERC20',
          name: '_baseCurrency',
          type: 'address',
        },
        {
          internalType: 'contract Swap',
          name: '_swap',
          type: 'address',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'Paused',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'Unpaused',
      type: 'event',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_address',
          type: 'address',
        },
        {
          components: [
            {
              internalType: 'contract ERC20',
              name: 'token',
              type: 'address',
            },
            {
              internalType: 'uint24',
              name: 'fee',
              type: 'uint24',
            },
          ],
          internalType: 'struct Balancer.Param[]',
          name: '_params',
          type: 'tuple[]',
        },
      ],
      name: 'autoRun',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'baseCurrency',
      outputs: [
        {
          internalType: 'contract ERC20',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'contract ERC20',
          name: '_tokenOut',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '_value',
          type: 'uint256',
        },
      ],
      name: 'getAmountOutMinimumFromBaseCurrency',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'contract ERC20',
          name: '_tokenOut',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
      ],
      name: 'getAmountOutMinimumFromNotBaseCurrency',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'contract ERC20',
          name: '_tokenOut',
          type: 'address',
        },
      ],
      name: 'getBasePriceToken',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getPosition',
      outputs: [
        {
          components: [
            {
              internalType: 'bool',
              name: 'isCreated',
              type: 'bool',
            },
            {
              internalType: 'bool',
              name: 'isAutomated',
              type: 'bool',
            },
            {
              internalType: 'uint256',
              name: 'balanceWhen',
              type: 'uint256',
            },
            {
              components: [
                {
                  internalType: 'contract ERC20',
                  name: 'token',
                  type: 'address',
                },
                {
                  internalType: 'contract AggregatorV3Interface',
                  name: 'chainLinkFeed',
                  type: 'address',
                },
                {
                  internalType: 'string',
                  name: 'symbol',
                  type: 'string',
                },
                {
                  internalType: 'uint256',
                  name: 'amount',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'decimals',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'percentage',
                  type: 'uint256',
                },
                {
                  components: [
                    {
                      internalType: 'uint256',
                      name: 'value',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'decimals',
                      type: 'uint256',
                    },
                  ],
                  internalType: 'struct Balancer.Price',
                  name: 'price',
                  type: 'tuple',
                },
              ],
              internalType: 'struct Balancer.Token[]',
              name: 'tokens',
              type: 'tuple[]',
            },
          ],
          internalType: 'struct Balancer.PositionDefinition',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'contract ERC20',
          name: '_tokenOut',
          type: 'address',
        },
      ],
      name: 'getPriceTokenOut',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_index',
          type: 'uint256',
        },
      ],
      name: 'getTimeline',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'timestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'balanceWhen',
              type: 'uint256',
            },
            {
              components: [
                {
                  internalType: 'contract ERC20',
                  name: 'token',
                  type: 'address',
                },
                {
                  internalType: 'contract AggregatorV3Interface',
                  name: 'chainLinkFeed',
                  type: 'address',
                },
                {
                  internalType: 'string',
                  name: 'symbol',
                  type: 'string',
                },
                {
                  internalType: 'uint256',
                  name: 'amount',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'decimals',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'percentage',
                  type: 'uint256',
                },
                {
                  components: [
                    {
                      internalType: 'uint256',
                      name: 'value',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'decimals',
                      type: 'uint256',
                    },
                  ],
                  internalType: 'struct Balancer.Price',
                  name: 'price',
                  type: 'tuple',
                },
              ],
              internalType: 'struct Balancer.Token[]',
              name: 'tokens',
              type: 'tuple[]',
            },
          ],
          internalType: 'struct Balancer.TimelineDefinition',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      name: 'listOfAllowedTokens',
      outputs: [
        {
          internalType: 'contract ERC20',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'paused',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'contract ERC20',
              name: 'token',
              type: 'address',
            },
            {
              internalType: 'uint24',
              name: 'fee',
              type: 'uint24',
            },
          ],
          internalType: 'struct Balancer.Param[]',
          name: '_params',
          type: 'tuple[]',
        },
      ],
      name: 'run',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'contract ERC20',
          name: '_token',
          type: 'address',
        },
        {
          internalType: 'contract AggregatorV3Interface',
          name: '_chainLinkFeed',
          type: 'address',
        },
      ],
      name: 'setAllowedToken',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: '_value',
          type: 'bool',
        },
      ],
      name: 'setAutomatic',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_value',
          type: 'uint256',
        },
      ],
      name: 'setBalanceWhen',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'contract ERC20',
          name: '_baseCurrency',
          type: 'address',
        },
      ],
      name: 'setBaseCurrency',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'contract ERC20',
              name: 'token',
              type: 'address',
            },
            {
              internalType: 'contract AggregatorV3Interface',
              name: 'chainLinkFeed',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'percentage',
              type: 'uint256',
            },
          ],
          internalType: 'struct Position.Token[]',
          name: '_tokens',
          type: 'tuple[]',
        },
      ],
      name: 'setPosition',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
}
