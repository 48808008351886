<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import { mapGetters, mapActions } from 'vuex'
import store from '@/store'
import { getPosition, getSnap } from '@/services'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters({
      account: 'wallet/getAccount',
      getLogout: 'wallet/getLogout',
    }),
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  async created() {
    this.setupWallet()
    this.setupLocale()

    this.$root.$on('initConnect', this.connect)
  },

  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },

  methods: {
    ...mapActions({
      setPosition: 'balancer/setPosition',
      setSnap: 'balancer/setSnap',
      setAccount: 'wallet/setAccount',
      setLogout: 'wallet/setLogout',
    }),

    async connect() {
      try {
        const result = await this.requestAccounts()
        if (await this.getChainId() === 137) {
          this.setupLoad(result[0])
          this.setLogout(0)
          return
        }
        await this.switchEthereumChain()
        await this.connect()
      } catch (e) {
        if (e.code === 4902) {
          await this.addEthereumChain()
          await this.connect()
        }
        if (e.code === -32002) {
          alert('Check your metamask')
        }
      }
    },

    async chainChanged(chainId) {
      const result = parseInt(chainId, 16)
      if (![137].includes(result)) {
        this.setAccount('')
        this.$bvToast.toast('unsupported network', { title: `ChainId: ${result}`, variant: 'danger', solid: true })
        return
      }
      await this.connect()
    },

    async verifyAccounts() {
      const accounts = await this.requestAccounts()
      if (accounts !== null && accounts.length > 0) {
        await this.connect()
      }
    },

    setupWallet() {
      const walletLogout = window.localStorage.getItem('wallet_logout')

      if (this.provider() !== null) {
        if (walletLogout !== null && walletLogout !== '1') {
          this.verifyAccounts()
        }

        window.ethereum.on('accountsChanged', accounts => {
          if (this.account !== '') this.setupLoad(accounts[0])
        })

        window.ethereum.on('chainChanged', async chainId => {
          this.chainChanged(chainId)
        })
      }
    },

    async setupLoad(account) {
      this.setPosition(await getPosition(this.provider().getSigner(), account))
      this.setAccount(account)
      this.setSnap(await getSnap(this.provider().getSigner(), account))
    },

    setupLocale() {
      const lang = window.localStorage.getItem('locale') || (navigator.language || navigator.userLanguage).toLowerCase()
      if (['pt-br', 'pt'].includes(lang)) {
        this.setLocale('pt')
      } else {
        this.setLocale(lang)
      }
    },
  },
}
</script>
