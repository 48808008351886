import * as ethers from 'ethers'
import { BigNumber } from 'ethers'
import balancer from '@core/abi/balancer'

export const contractBalancer = signer => new ethers.Contract(balancer.address, balancer.abi, signer)

export const sendPosition = async (signer, tokens, gasPrice) => {
  const contract = contractBalancer(signer)
  return contract.setPosition(tokens, {
    gasPrice,
    gasLimit: BigNumber.from('800000'),
    nonce: await signer.getTransactionCount(signer.address),
  })
}

export default {
  sendPosition,
  contractBalancer,
}
