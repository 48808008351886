import * as ethers from 'ethers'
import { contractBalancer, shortNameAddress } from '@/libs/helpers'
import balancer from '@core/abi/balancer'
import { ERC20Allowance } from './token'

export const getPosition = async (provider, owner) => {
  const contract = contractBalancer(provider)
  const row = await contract.getPosition()
  let sumValue = 0

  const {
    isCreated, isAutomated, balanceWhen, tokens,
  } = row
  const newTokens = []

  for await (const tokenRow of tokens) {
    const {
      token, symbol, price, percentage, chainLinkFeed, amount, decimals,
    } = tokenRow

    const allowedValue = await ERC20Allowance(provider, token, owner, balancer.address)
    const item = {
      title: `${symbol} - ${shortNameAddress(token)}`,
      token,
      chainLinkFeed,
      allowedValue,
      needApproval: false,
      symbol,
      percentage: parseFloat(ethers.utils.formatUnits(percentage, 2)),
      price: parseFloat(ethers.utils.formatUnits(price.value, price.decimals.toNumber())),
      amount: parseFloat(ethers.utils.formatUnits(amount, decimals.toNumber())),
    }
    sumValue += (item.price * item.amount)
    newTokens.push(item)
  }

  const position = {
    isCreated,
    isAutomated,
    sumValue: parseFloat(sumValue.toFixed(2)),
    balanceWhen: parseFloat(ethers.utils.formatUnits(balanceWhen, 2)),
    tokens: newTokens.map(t => {
      const condValue = (sumValue < 1000) ? 1000 : sumValue
      const verifyValue = (t.price * t.allowedValue)

      if (verifyValue <= condValue) {
        t.needApproval = true
      }

      return t
    }),
  }

  return position
}

export default {
  getPosition,
}
