import Vue from 'vue'
import VueRouter from 'vue-router'

import dashboard from './routes/dashboard'
import balancer from './routes/balancer'
// import marketMaker from './routes/market-maker'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'dashboard' } },
    // ...dashboard,
    // ...marketMaker,
    ...balancer,
    {
      path: '*',
      redirect: '/balancer/wallet',
    },
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
