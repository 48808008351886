import * as ethers from 'ethers'

export const shortNameAddress = value => value.substring(0, 7).concat('...').concat(value.substring(value.length - 5))
export const formatPercentage = value => {
  let newValue = ''
  let index = 0
  while (index < 4) {
    if (value.length > index) newValue += value[index]
    else newValue += '0'
    index += 1
  }
  return parseFloat(newValue.substring(0, 2).concat('.').concat(newValue.substring(newValue.length - 2)))
}

export const itemPosition = (token, chainLinkFeed, value) => ({
  token,
  chainLinkFeed,
  percentage: ethers.utils.parseUnits(value.toString(), 2),
})

export const sleep = seconds => new Promise(resolve => setTimeout(resolve, seconds * 1000))

export default {
  shortNameAddress,
  formatPercentage,
  itemPosition,
  sleep,
}
