import { formatUnits, parseUnits } from 'ethers/lib/utils'

export const changeGasIncrease = async (provider, percentageIncrease = 10) => {
  const gp = await provider.getGasPrice()
  const value = parseInt(formatUnits(gp.toString(), 'gwei'))

  // create new value
  const newValueGwei = value + parseInt(((value * percentageIncrease) / 100).toString())
  return parseUnits(newValueGwei.toString(), 'gwei')
}

export default {
  changeGasIncrease,
}
