export default [
  {
    path: '/balancer/wallet',
    name: 'balancerWallet',
    component: () => import('@/views/balancer/wallet/index.vue'),
    meta: {
      pageTitle: 'Balancer',
      breadcrumb: [
        {
          text: 'Wallet',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/balancer/sub-accounts',
  //   name: 'balancerSubAccounts',
  //   component: () => import('@/views/balancer/sub-accounts/index.vue'),
  //   meta: {
  //     pageTitle: 'Balancer',
  //     breadcrumb: [
  //       {
  //         text: 'Sub accounts',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
