import { shortNameAddress } from '../../libs/helpers'

export default {
  namespaced: true,
  state: {
    account: '',
    logout: false,
  },
  getters: {
    getAccount(state) {
      return state.account
    },
    getAccountShortName(state) {
      const name = state.account
      return name.length > 0 ? shortNameAddress(name) : name
    },
    getLogout(state) {
      return state.logout
    },
  },
  mutations: {
    UPDATE_ACCOUNT(state, account) {
      state.account = account
      window.localStorage.setItem('wallet_account', account)
    },
    LOGOUT(state, logout) {
      state.logout = logout
      window.localStorage.setItem('wallet_logout', logout)
    },
  },
  actions: {
    async setAccount({ commit }, account) {
      commit('UPDATE_ACCOUNT', account)
    },
    setLogout({ commit }, logout) {
      commit('LOGOUT', logout)
    },
  },
}
