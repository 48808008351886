export default {
  address: '0x5a625673B74b3e47fd442F52a104021839ca903c',
  abi: [
    {
      inputs: [
        {
          internalType: 'address',
          name: '_accessAddress',
          type: 'address',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'accessedBy',
          type: 'address',
        },
      ],
      name: 'AccessNotAllowed',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: '_addressBy',
          type: 'address',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'timestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'balanceWhen',
              type: 'uint256',
            },
            {
              components: [
                {
                  internalType: 'contract ERC20',
                  name: 'token',
                  type: 'address',
                },
                {
                  internalType: 'contract AggregatorV3Interface',
                  name: 'chainLinkFeed',
                  type: 'address',
                },
                {
                  internalType: 'uint256',
                  name: 'price',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'amount',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'percentage',
                  type: 'uint256',
                },
              ],
              internalType: 'struct Timeline.Token[]',
              name: 'tokens',
              type: 'tuple[]',
            },
          ],
          indexed: false,
          internalType: 'struct Timeline.Snap',
          name: '_snap',
          type: 'tuple',
        },
      ],
      name: 'NewSnap',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_user',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '_index',
          type: 'uint256',
        },
      ],
      name: 'findSnapByAddressAndIndex',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'timestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'balanceWhen',
              type: 'uint256',
            },
            {
              components: [
                {
                  internalType: 'contract ERC20',
                  name: 'token',
                  type: 'address',
                },
                {
                  internalType: 'contract AggregatorV3Interface',
                  name: 'chainLinkFeed',
                  type: 'address',
                },
                {
                  internalType: 'uint256',
                  name: 'price',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'amount',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'percentage',
                  type: 'uint256',
                },
              ],
              internalType: 'struct Timeline.Token[]',
              name: 'tokens',
              type: 'tuple[]',
            },
          ],
          internalType: 'struct Timeline.Snap',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_accessAddress',
          type: 'address',
        },
      ],
      name: 'getAllowedAccess',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_user',
          type: 'address',
        },
      ],
      name: 'numberOfSnap',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_accessAddress',
          type: 'address',
        },
      ],
      name: 'setAccessAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_user',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'balanceWhen',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'contract ERC20',
              name: 'token',
              type: 'address',
            },
            {
              internalType: 'contract AggregatorV3Interface',
              name: 'chainLinkFeed',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'price',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'percentage',
              type: 'uint256',
            },
          ],
          internalType: 'struct Timeline.Token[]',
          name: 'tokens',
          type: 'tuple[]',
        },
      ],
      name: 'setSnap',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
}
