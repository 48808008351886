import * as ethers from 'ethers'
import { contractBalancer, shortNameAddress, formatPercentage } from '@/libs/helpers'
import timeline from '@core/abi/timeline'

const parseRowToken = row => {
  const newRow = {}

  const {
    token, symbol, price, percentage, chainLinkFeed, amount, decimals,
  } = row

  newRow.title = `${symbol} - ${shortNameAddress(token)}`
  newRow.symbol = symbol
  newRow.token = token
  newRow.chainLinkFeed = chainLinkFeed
  newRow.percentage = `${formatPercentage(`${ethers.utils.formatUnits(percentage, 2)}`.replace('.', ''))}%`
  newRow.price = parseFloat(ethers.utils.formatUnits(price.value, price.decimals.toNumber()))
  newRow.amount = parseFloat(ethers.utils.formatUnits(amount, decimals.toNumber()))
  newRow.value = parseFloat((newRow.amount * newRow.price).toFixed(2))

  return newRow
}

const parseRow = row => {
  const newRow = {}
  const { balanceWhen, timestamp, tokens } = row
  const date = new Date(timestamp.toNumber() * 1000)

  newRow.balanceWhen = parseFloat(ethers.utils.formatUnits(balanceWhen, 2))
  newRow.timestamp = `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`
  newRow.tokens = tokens.map(t => parseRowToken(t))
  newRow.total = parseFloat(newRow.tokens.map(t => t.value).reduce((p, c) => p + c, 0).toFixed(2))
  return newRow
}

export const contractTimelineCreate = signer => new ethers.Contract(timeline.address, timeline.abi, signer)

export const getSnap = async (provider, owner) => {
  const contract = contractBalancer(provider)
  const contractTimeline = contractTimelineCreate(provider)
  const numberOfSnap = await (await contractTimeline.numberOfSnap(owner)).toNumber()
  const rows = []

  let qtd = 0
  for (let key = numberOfSnap - 1; (key >= 0 && qtd < 5); key -= 1) {
    qtd += 1
    const row = await contract.getTimeline(key)
    rows.push(parseRow(row))
  }

  return rows
}

export default {
  getSnap,
}
