import { setTimeout } from 'core-js'
import { shortNameAddress } from '../../libs/helpers'

export default {
  namespaced: true,
  state: {
    showCharts: true,
    balancer: {
      glassPosition: {
        isCreated: false,
        sumValue: 0,
        isAutomated: false,
        balanceWhen: 3.00,
        tokens: [],
      },
      position: {
        isCreated: false,
        sumValue: 0,
        isAutomated: false,
        balanceWhen: 3.00,
        tokens: [],
      },
      snap: [],
    },
    network: 'polygon',
    tokens: {
      polygon: [
        { name: 'USDC', address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', chainLinkFeed: '0xfe4a8cc5b5b2366c1b58bea3858e81843581b2f7' },
        { name: 'WBTC', address: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6', chainLinkFeed: '0xc907e116054ad103354f2d350fd2514433d57f6f' },
        { name: 'WETH', address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', chainLinkFeed: '0xf9680d99d6c9589e2a93a78a04a279e509205945' },
      ],
    },
  },
  getters: {
    showCharts(state) {
      return state.showCharts
    },
    position(state) {
      return state.balancer.position
    },
    glassPosition(state) {
      return state.balancer.glassPosition
    },
    snap(state) {
      return state.balancer.snap
    },
    network(state) {
      return state.network
    },
    tokens(state) {
      const values = state.tokens[state.network]
      return values.map(t => {
        t.title = `${shortNameAddress(t.address)} - ${t.name}`
        return t
      })
    },
  },
  mutations: {
    SET_POSITION(state, position) {
      state.balancer.position = position
      state.balancer.glassPosition = JSON.parse(JSON.stringify(position))
    },
    SET_SNAP(state, snap) {
      state.balancer.snap = snap
    },
    SET_SHOW_CHARTS(state, value) {
      state.showCharts = value
    },
  },
  actions: {
    setPosition({ commit }, position) {
      commit('SET_SHOW_CHARTS', false)
      commit('SET_POSITION', position)
      setTimeout(() => commit('SET_SHOW_CHARTS', true), 300)
    },
    setSnap({ commit }, snap) {
      commit('SET_SNAP', snap)
    },
    setShowCharts({ commit }, value) {
      commit('SET_SHOW_CHARTS', value)
    },
  },
}

/**
  token
  ------------------------------------
  ERC20 token;
  AggregatorV3Interface chainLinkFeed;
  string symbol;
  uint256 amount;
  uint256 decimals;
  uint256 percentage;
  Price price;
*/

/**
  snap
  ---------------------
  uint256 timestamp;
  uint256 balanceWhen;
  Token[] tokens;
*/
