import { ethers } from 'ethers'

let provider = null

export default {
  methods: {
    provider() {
      if (!(window.ethereum && window.ethereum.isMetaMask === true)) return null
      provider = new ethers.providers.Web3Provider(window.ethereum)
      return provider
    },

    async requestAccounts() {
      const result = await this.provider().send('eth_requestAccounts', [])
      return result
    },

    async addEthereumChain() {
      const result = await this.provider().send('wallet_addEthereumChain', [{
        chainId: ethers.utils.hexlify(137),
        rpcUrls: ['https://polygon-mainnet.infura.io/v3/06bdfbf634b14a358a49cd04adbe37f1'],
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
        blockExplorerUrls: ['https://polygonscan.com/'],
      }])
      return result
    },

    async switchEthereumChain() {
      const result = await this.provider().send('wallet_switchEthereumChain', [{ chainId: ethers.utils.hexlify(137) }])
      return result
    },

    async getChainId() {
      const { chainId } = await this.provider().getNetwork()
      return chainId
    },

    setLocale(locale) {
      this.$i18n.locale = locale
      window.localStorage.setItem('locale', locale)
    },
  },
}
